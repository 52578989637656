<template>
  <ZMainPage type="card">
    <el-card shadow="never" class="border-none mb-2">
      <template #header><span class="tb">学生基础信息</span></template>
      <LineWrapper labelWidth="140rem" class="pl-3">
        <LineItem v-for="(item, index) in list1" :key="index" :label="item.label" :value="item.value"></LineItem>
      </LineWrapper>
    </el-card>
    <el-card shadow="never" class="border-none mb-2">
      <template #header><span class="tb">艺体生转入/转出信息</span></template>
      <LineWrapper labelWidth="140rem" class="pl-3">
        <LineItem v-for="(item, index) in list2" :key="index" :label="item.label">
          <template v-if="item.value || item.value === 0">{{ item.value }}</template>
          <template v-else>
            <el-tag class="mr-1" v-for="(child, idx) in item.children" :key="idx">{{ child }}</el-tag>
          </template>
        </LineItem>
      </LineWrapper>
    </el-card>
    <el-card shadow="never" class="border-none mb-2">
      <template #header><span class="tb">操作记录</span></template>
      <TimeLine :list="list3" reverse>
        <template #[index] v-for="(item, index) in list3">
          <div class="flex">
						<div class="w-260 mr-3 flex">
              <div class="w-120">{{ item.l1 }}: </div>
							<div :style="{minWidth: item.id === 3 ? '480rem' : ''}">{{ item.content }}</div>
            </div>
            <div class="flex" v-if="item.l2">
              <div class="w-120">{{ item.l2 }}: </div>
              <div>{{ item.date }}</div>
            </div>
          </div>
        </template>
      </TimeLine>
    </el-card>
    <el-card shadow="never" class="border-none">
      <el-button @click="$router.back()">返回</el-button>
    </el-card>
  </ZMainPage>
</template>

<script>
import TimeLine from "@/components/common/TimeLine";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import {as2_apply_show} from "@/api";
import {pageLoadingMixin} from "@/utils/mixins";

export default {
	_config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  components: { TimeLine, LineItem, LineWrapper },
  mixins: [pageLoadingMixin],
  data() {
    return {
      list1: [],
      list2: [],
      list3: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.httpStart()
      as2_apply_show({ id: this.$route.query.id }).then(res => {
        const { type, combined_subjects_text, status, creator, created_at, status_text, judge_time, reject_reason } = res
        const combined_text = combined_subjects_text.join('、') || ''
        const list1 = [
          { label: '学生姓名', value: `${res.student || ''}（${res.student_no || ''}）` },
          { label: '校区', value: res.school },
          { label: '年级', value: res.grade },
          { label: '行政班', value: res.class_name }
        ]
        const list2 = [
          { label: '艺体生转入/转出', value: res.type_text },
          { label: '转入行政班', value: res.after_class },
          { label: '转入班主任', value: res.after_teacher },
          { label: '转入教学班', children: res.teaching },
          { label: '转入专业方向', value: res.major },
          { label: '对应专业老师', value: res.major_teacher },
          { label: '科目组合', value: combined_text }
        ]
        list2.push({ label: `转${type === 1 ? '入' : '出'}艺体时间`, value: res.art_at })
        if(type === 1) {
          list2.push({ label: '应缴总金额', value: res.payable_amount })
        }
        list2.push({ label: type === 1 ? '说明' : '转出原因', value: res.describe || '-' })

				const statusArr = ['审核中', '通过', '驳回', '撤销', '失败']
        const list3 = [
          { id: 1, content: creator, l1: `${res.type_text}发起人`, l2: '发起时间', date: created_at },
          { id: 2, content: status_text, l1: '企微审批状态' },
        ]
        if(status) {
          list3[1].l2 = `${statusArr[status]}时间`
          list3[1].date = judge_time
					if (status === 2) {
						list3.push({id: 3, content: reject_reason, l1: `${statusArr[status]}原因`})
					}
					if (status === 4) {
						list3.push({id: 3, content: reject_reason, l1: `转入${statusArr[status]}原因`})
					}
        }
        this.list1 = list1
        this.list2 = list2
        this.list3 = list3
      }).finally(() => this.httpEnd())
    }
  }
}
</script>

<style lang="scss" scoped>
.w-120 {
	min-width: 120rem;
}
.w-260 {
  width: 260rem;
}
</style>
